import React, { useState } from 'react'
import { graphql, navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled, { css } from 'styled-components'
import useLocalStorageState from 'use-local-storage-state'

// helpers
import { up, addResponsivity } from '../lib/styles'

// components
import Col, { BasicPageContentCol } from '../components/Col'
import Title from '../components/Title'
import Gap from '../components/Gap'
import Link from '../components/Link'
import Text, { BasicWhiteText } from '../components/Text'
import SEO from '../components/SEO'
import Button from '../components/Button'

import { Page, PageContent, PageWithBgImage } from '../components/Page'

import quizConf from '../data/quiz'
import CloseQuizButton from '../components/CloseQuizButton'
import tvIconPath from '../data/images/ico/quizTvIcon.svg'
import QuizOption from '../components/QuizOption'

import { extractNodesFromData } from '../lib/helpers'
import LineSeparator from '../components/LineSeparator'
import ShareWidget from '../components/ShareWidget'

const { qas, results } = quizConf

const TV_ICON_BP = 1050

const HiddenContent = styled.div`
  font-size: 0;
  visibility: hidden;
`

const TvIconWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    ${addResponsivity}
    margin-top: -8px;

    ${up(TV_ICON_BP)} {
      margin-top: 0px;
      position: absolute;
      transform: translate(-100%, 0) translate(-40px, 0);
    }
  `}
`

const size2 = 680 + 16 + 2 * 16
const size6 = 720 + 32 + 2 * 16

const ImageOptionsWrapper = styled.div`
  ${({ theme: { colors }, optionsLength }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    align-self: flex-start;

    width: 100vw;
    max-width: ${optionsLength === 6 ? size6 : size2}px;

    padding-right: 40px;
    margin: -16px 0 0 -24px;
  `}
`

const getQuestionPoints = (questionIndex, answerIndex) => {
  const qa = qas[questionIndex]

  if (answerIndex !== -1 && qa.answers[answerIndex]) {
    const ans = qa.answers[answerIndex]

    return ans.points
  }

  return null
}

const getQuizPoints = history => {
  const allPoints = history.map(userInput => {
    // userInput = { questionIndex, answerIndex }
    const qa = qas[userInput.questionIndex]
    const ans = qa.answers[userInput.answerIndex]

    return ans.points
  })

  return allPoints
}

const convertPointsToResults = points =>
  Object.keys(points).reduce((acc, numberOfPoints) => {
    const resultsArr = points[numberOfPoints]

    const resultsWithPoints = resultsArr.reduce((acc, resultIndex) => {
      acc[resultIndex] = parseInt(numberOfPoints, 10)
      return acc
    }, {})

    return {
      ...acc,
      ...resultsWithPoints,
    }
  }, {})

const getActualQuizState = ({
  history,
  currentQuestionIndex,
  currentAnswerIndex,
}) => {
  const currentQuestionPoints = getQuestionPoints(
    currentQuestionIndex,
    currentAnswerIndex,
  )

  const historyPoints = getQuizPoints(history)

  // all points even with currently selected answer
  const totalPoints = [...historyPoints, currentQuestionPoints].filter(a => a)

  // console.log('totalPoints', totalPoints)

  // iterate over totalPoints object (which contains results points)
  const totalPointsConvertedToResults = totalPoints.map(oneAnswerPoints =>
    convertPointsToResults(oneAnswerPoints))

  // console.log('totalPointsConvertedToResults', totalPointsConvertedToResults)

  const resultsPoints = totalPointsConvertedToResults.reduce(
    (acc, currQuestionPoints) => {
      Object.keys(currQuestionPoints).forEach(resultIndex => {
        if (acc[resultIndex]) {
          acc[resultIndex] += currQuestionPoints[resultIndex]
        } else {
          acc[resultIndex] = currQuestionPoints[resultIndex]
        }
      })
      return acc
    },
    {},
  )

  // console.log('resultsPoints', resultsPoints)

  const finalResult = Object.keys(resultsPoints).reduce(
    (result, currResultKey) => {
      if (result.resultPoints < resultsPoints[currResultKey]) {
        return {
          resultKey: currResultKey,
          resultPoints: resultsPoints[currResultKey],
        }
      }
      return result
    },
    { resultKey: -1, resultPoints: 0 },
  )

  return {
    finalResult,
    resultsPoints,
    currentQuestionPoints,
    answeredCount: totalPoints.length,
  }
}

const findNextQuestionIndex = (history, currentIndex, allQuestionsLength) => {
  if (currentIndex === 0) {
    return 1
  }
  if (history.length < currentIndex) {
    return 0
  }
  return currentIndex + 1

  // const historyMap = history.reduce((acc, curr) => {
  //   acc[curr.questionIndex] = true
  //   return acc
  // }, {})

  // console.log('historyMap', historyMap)

  // for (let index = 0; index < allQuestionsLength; index += 1) {
  //   if (!historyMap[index] && currentIndex !== index) {
  //     console.log('return this!', index)
  //     return index
  //   }
  // }

  // return history.length + 1
}

export default function QuizTemplate({
  data,
  path,
  pageContext,
  location: { pathname },
  ...rst
}) {
  const { allQuizImages } = extractNodesFromData(data)
  const { index: questionIndex } = pageContext
  const qa = qas[questionIndex]
  // console.log('allQuizImages', allQuizImages)

  const answers = qa.answers.map(ans => {
    let imageFile = null

    if (ans.image) {
      imageFile = allQuizImages.find(
        img => img.relativePath === `images/quiz/${ans.image}`,
      )
    }

    return { ...ans, imageFile }
  })

  const imageAnswersLength = answers.some(ans => ans.image) && answers.length

  let [answersHistory, setAnswersHistory] = useLocalStorageState(
    'quiz-answers',
    [],
  )

  const [isQuizDone, setIsQuizDone] = useLocalStorageState('quiz-done', false)

  // user was already here
  const answerHistory = answersHistory.find(
    answer => answer.questionIndex === questionIndex,
  )

  const defaultSelected = answerHistory ? answerHistory.answerIndex : -1
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(
    defaultSelected,
  )

  // delete this page question, we add it on btn click again
  answersHistory = answersHistory.filter(
    answer => answer.questionIndex !== questionIndex,
  )

  // calculate alle fields based on state
  const enhancedQuizState = getActualQuizState({
    history: answersHistory,
    currentQuestionIndex: questionIndex,
    currentAnswerIndex: selectedAnswerIndex,
  })

  const { finalResult } = enhancedQuizState

  const handleOptionClick = optionIndex => {
    setSelectedAnswerIndex(optionIndex)
  }

  // console.log('selectedAnswerIndex', selectedAnswerIndex)
  // console.log('enhancedQuizState', enhancedQuizState)

  const nextQuestionIndex = findNextQuestionIndex(
    answersHistory,
    questionIndex,
    qas.length,
  )
  const link = nextQuestionIndex ? nextQuestionIndex + 1 : ''

  const handleNextPageClick = () => {
    if (selectedAnswerIndex === -1) {
      alert('Pro pokračování vyberte prosím odpověď.')
      return
    }

    if (nextQuestionIndex < qas.length) {
      setAnswersHistory([
        ...answersHistory,
        {
          questionIndex,
          answerIndex: selectedAnswerIndex,
        },
      ])

      navigate(`${Link.QUIZ}/${link}`)
    } else {
      // reset quiz
      setIsQuizDone(true)
      navigate(`${Link.RESULTS_ROOT}/${results[finalResult.resultKey]?.slug}`)
      // setTimeout(() => {}, 1)
      setAnswersHistory([])
    }
  }

  const areImagesOptions = imageAnswersLength > 0

  const renderedOptions = (
    <>
      {answers.map((a, index) => (
        <QuizOption
          {...a}
          key={index}
          index={index}
          isSelected={selectedAnswerIndex === index}
          handleClick={() => handleOptionClick(index)}
          imageAnswersLength={imageAnswersLength}
        />
      ))}
    </>
  )

  return (
    <>
      <SEO
        title={`Kvíz - ${questionIndex + 1} / ${qas.length}`}
        pathname={pathname}
        image="/fb-share-quiz.jpg"
        description="Poznáváte se občas v některých seriálových postavách? Anebo v nich vidíte ostatní členy svého rodinného klanu? Máte v rodině nějakého J. R. Ewinga, primáře Sovu nebo snad Homera Simpsona? Odpovězte na pár otázek a zjistíte, jaký seriál váš rodinný podnik připomíná."
      />
      <HiddenContent>
        {/* this is for prefetching content (page data) */}
        {questionIndex < qas.length - 1 &&
          <Link to={`${Link.QUIZ}/${link}`}>NEXT LINK</Link>}
        {questionIndex === qas.length - 1 && (
          <>
            {Object.keys(results)
              .map(key => results[key].slug)
              .map(link =>
                <Link to={`${Link.RESULTS_ROOT}/${link}`}>result</Link>)}
          </>
        )}
      </HiddenContent>
      <PageWithBgImage blurred blurTransition={questionIndex === 0}>
        <PageContent>
          <Col alignItems="flex-end">
            <Gap gap="0" mobileGap="24px" />
            <CloseQuizButton noMobilePadding={questionIndex === 0} />
          </Col>
          <BasicPageContentCol>
            {questionIndex === 0 && (
              <>
                <TvIconWrapper>
                  <img src={tvIconPath} alt="" />
                </TvIconWrapper>
                <Gap gap="0" mobileGap="16px" bp={TV_ICON_BP} />

                <Title
                  as="h1"
                  mobileFontSize="32px"
                  fontSize="40px"
                  lineHeight="130%"
                  fontWeight="900"
                  color="gold"
                  letterSpacing="0.2px"
                >
                  Rodina jako z Netflixu. Kdyby vaše firma byla seriál, který by
                  to byl?
                </Title>
                <Gap gap="16px" />
                <BasicWhiteText>
                  Poznáváte se občas v některých seriálových postavách? Anebo v
                  nich vidíte ostatní členy svého rodinného klanu? Máte v rodině
                  nějakého J. R. Ewinga, primáře Sovu nebo snad Homera Simpsona?
                  Odpovězte na pár otázek a zjistíte, jaký seriál váš rodinný
                  podnik připomíná. Třeba najdete inspiraci, jak dostat firmu
                  outside the box. Nebo aspoň zjistíte, co si máte při příští
                  rodinné sešlosti pustit společně v televizi.
                </BasicWhiteText>
                <Gap gap="40px" mobileGap="32px" />

                <ShareWidget
                  path={Link.QUIZ}
                  what="kvíz"
                  title="Kvíz - Forbes speciál: Jak budovat a řídit firmu, která vydrží 100 let"
                />
                <Gap gap="40px" mobileGap="32px" />
                <LineSeparator />
                <Gap gap="40px" mobileGap="32px" />
              </>
            )}

            <Text font="public" weight="800" color="gold">
              {`${questionIndex + 1} / ${qas.length}`}
            </Text>
            <Gap gap="8px" />
            <Title.QuizQuestionTitle as={questionIndex === 0 && 'h2'}>
              {qa.question}
            </Title.QuizQuestionTitle>
            <Gap gap="24px" />
          </BasicPageContentCol>

          {areImagesOptions && (
            <BasicPageContentCol>
              <ImageOptionsWrapper optionsLength={imageAnswersLength}>
                {renderedOptions}
              </ImageOptionsWrapper>
            </BasicPageContentCol>
          )}
          {!areImagesOptions &&
            <BasicPageContentCol>{renderedOptions}</BasicPageContentCol>}

          <Gap gap="24px" />
          <BasicPageContentCol>
            <Col
              mobileWidth="100%"
              width="auto"
              bp={areImagesOptions ? 400 : 'mobile'}
            >
              <Button type="primary" onClick={handleNextPageClick}>
                {nextQuestionIndex < qas.length
                  ? 'další otázka'
                  : 'Vyhodnocení'}
              </Button>
            </Col>
          </BasicPageContentCol>

          <Gap gap="100px" />
        </PageContent>
      </PageWithBgImage>
    </>
  )
}

export const pageQuery = graphql`
  query {
    allQuizImages: allFile(
      filter: { relativePath: { regex: "/^images/quiz/" } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(
              width: 320
              height: 180
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`

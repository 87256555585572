import React, { Component, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { addResponsivity, up, down, between } from '../lib/styles'
import { buzzOut } from '../lib/animations'

import ShareFb from '../data/images/ico/share-fb-colorized.inline.svg'
import ShareTwitter from '../data/images/ico/share-twitter-colorized.inline.svg'
import ShareMore from '../data/images/ico/share-more-colorized.inline.svg'
import ShareLinkedIn from '../data/images/ico/share-linkedin.inline.svg'
import ShareCopyUrl from '../data/images/ico/share-copy-url.inline.svg'
import ShareMail from '../data/images/ico/share-mail.inline.svg'
import VH from './VH'
import Row from './Row'
import Text from './Text'
import Gap from './Gap'

const TextAreaWrapper = styled.a`
  visibility: hidden;
`
const ShareLink = styled.a`
  ${({ theme: { colors }, animation }) => css`
    font-size: 0;
    cursor: pointer;

    ${animation &&
    css`
      animation: ${buzzOut} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    `}
  `}
`

const StyledImgWrapper = styled.div`
  ${({ theme: { colors }, colorized, stroke }) => css`
    font-size: 0;

    cursor: pointer;
    svg {
      path[stroke] {
        /* fill: ${!colorized && !stroke && 'white'}; */
        stroke: ${!colorized && 'white'};
      }
      path:not([fill]) {
        stroke: ${!colorized && 'white'};
      }
      path[fill] {
        fill: ${!colorized && 'white'};
      }
      circle {
        stroke: ${!colorized && 'white'};
      }
    }
  `}
`

const StyledIcon = ({ Component, colorized, alt, stroke, onClick }) => (
  <StyledImgWrapper colorized={colorized} stroke={stroke} onClick={onClick}>
    <Component alt={alt} />
  </StyledImgWrapper>
)

function ShareWidget({
  path = '',
  colorized,
  what = 'výsledek',
  withoutText,
  title = '',
}) {
  const {
    site: {
      siteMetadata: { siteUrl, twitterUsername },
    },
  } = useStaticQuery(query)

  const color = colorized ? 'black' : 'white'

  const [isOpen, setIsOpen] = useState(false)

  const [addAnimation, setAddAnimation] = useState(false)

  function copyToClipboard(textToCopy) {
    navigator.clipboard.writeText(textToCopy)
  }

  return (
    <Row alignSelf="flex-start" alignItems="center">
      {!withoutText && (
        <>
          <Text fontSize="16px" lineHeight="150%" color={color}>
            Sdílet {what}
          </Text>
          <Gap gap="16px" />
        </>
      )}
      <ShareLink
        href={`https://www.facebook.com/sharer/sharer.php?u=${siteUrl}${path}`}
        target="_blank"
        rel="noreferrer"
        title="Sdílet na Facebook"
      >
        <StyledIcon Component={ShareFb} colorized={colorized} />
      </ShareLink>
      <Gap gap="8px" />
      <ShareLink
        href={`https://twitter.com/share?url=${siteUrl}${path}&via=${twitterUsername.substring(
          1,
        )}`}
        target="_blank"
        rel="noreferrer"
        title="Sdílet na Twitter"
      >
        <StyledIcon Component={ShareTwitter} colorized={colorized} />
      </ShareLink>
      <Gap gap="8px" />

      {!isOpen && (
        <StyledIcon
          Component={ShareMore}
          colorized={colorized}
          title="Další možnosti"
          onClick={() => {
            setIsOpen(true)
          }}
        />
      )}

      {isOpen && (
        <>
          <ShareLink
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${siteUrl}${path}`}
            target="_blank"
            rel="noreferrer"
            title="Sdílet na LinkedIn"
          >
            <StyledIcon Component={ShareLinkedIn} colorized={colorized} />
          </ShareLink>

          <Gap gap="8px" />

          <ShareLink
            title="Zkopírovat odkaz"
            animation={addAnimation}
            onClick={() => {
              setAddAnimation(false)

              copyToClipboard(`${siteUrl}${path}`)
              requestAnimationFrame(() => {
                setAddAnimation(true)
              })
            }}
          >
            <StyledIcon Component={ShareCopyUrl} colorized={colorized} />
          </ShareLink>

          <Gap gap="8px" />

          <ShareLink
            href={`mailto:?subject=${title}&body=${siteUrl}${path}`}
            target="_blank"
            rel="noreferrer"
            title="Poslat na email"
          >
            <StyledIcon Component={ShareMail} colorized={colorized} />
          </ShareLink>
        </>
      )}
    </Row>
  )
}

export default ShareWidget

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl: url
        twitterUsername
      }
    }
  }
`

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { addResponsivity, up, down, between } from '../lib/styles'
import Text, { BasicWhiteText } from './Text'
import Col from './Col'
import Gap from './Gap'
import { lightenDarkenColor } from '../lib/adjustColor'

const indexMap = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']

const OptionIndexWrapper = styled.div`
  ${({ theme: { colors }, isSelected }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;
    flex-shrink: 0;

    border-radius: 200px;

    background: ${colors.gold};

    ${isSelected &&
    css`
      background: ${colors.red} !important;
    `}

    transition: 0.3s;
  `}
`

const OptionRowWrapper = styled.div`
  ${({ theme: { colors }, maxWidth }) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    cursor: pointer;
    max-width: ${maxWidth};

    &:hover ${OptionIndexWrapper} {
      background: ${lightenDarkenColor(colors.gold, 40)};
    }
  `}
`

const Wrapper = styled.div`
  ${({ theme: { colors }, isImageOption, maxWidth }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;

    ${isImageOption &&
    css`
      margin: 16px 0 0 24px;
      max-width: 320px;
    `}

    ${up('mobile')} {
      max-width: ${maxWidth};
    }

    &:hover ${OptionIndexWrapper} {
      background: ${lightenDarkenColor(colors.gold, 40)};
    }
  `}
`

const OptionIndex = ({ index, isSelected }) => (
  <OptionIndexWrapper isSelected={isSelected}>
    <Text
      color={isSelected ? 'white' : 'cyan'}
      weight="700"
      size="26px"
      lineHeight="100%"
      font="public"
      transition="0.3s"
    >
      {indexMap[index]}
    </Text>
  </OptionIndexWrapper>
)

function QuizOption({
  text,
  handleClick,
  index,
  isSelected,
  imageFile,
  imageAnswersLength,
}) {
  // console.log('isSelected', isSelected)
  const image = getImage(imageFile)

  const imageMaxWidth = imageAnswersLength === 6 ? '218px' : '320px'

  // console.log('imageAnswersLength', imageAnswersLength)

  return (
    <Wrapper
      onClick={handleClick}
      isImageOption={imageAnswersLength}
      maxWidth={imageFile && imageMaxWidth}
    >
      {imageFile && (
        <>
          <GatsbyImage
            image={image}
            alt={text}
            css={css`
              /* max-width: ${imageMaxWidth}; */
            `}
          />
          <Gap gap="16px" mobileGap="8px" />
        </>
      )}

      <OptionRowWrapper>
        <OptionIndex index={index} isSelected={isSelected} />
        <Gap gap="16px" />
        <Col alignSelf="stretch">
          <Gap.Fluid min="0" max="6px" grow="100" />
          <BasicWhiteText weight={isSelected ? 700 : 400}>
            {text}
          </BasicWhiteText>
        </Col>
      </OptionRowWrapper>
      <Gap gap="16px" mobileGap={imageFile ? '24px' : '16px'} />
    </Wrapper>
  )
}

export default QuizOption

/**
 * Almost all of our helper functions goes here
 * Style helpers go to ./style.js
 */

// extract nodes from edges, if no data returns empty array like in rest
// (graphql returns null if nothing is found in db...)
export const extractNodes = data =>
  (data && data.edges && data.edges.map(e => e.node)) || []

// extract all edges - nodes data from all data results from DB / graphql
export const extractNodesFromData = (propsData = {}) => {
  const transformed = { ...propsData }
  Object.keys(propsData).forEach(key => {
    // some data does not have to have edges, skip them
    if (propsData[key].edges) {
      transformed[key] = propsData[key].edges.map(e => e.node)
    }
  })

  return transformed
}

export function shuffleArray(array) {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  array = [...array]

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

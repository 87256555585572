import React from 'react'
import styled, { css } from 'styled-components'

const LineSeparator = styled.div`
  ${({ theme: { colors } }) => css`
    background: ${colors.red};
    width: 88px;
    height: 4px;
  `}
`

export default LineSeparator
